import React, { useMemo, useCallback, useState, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import axios from 'client/axios';
import { ConfirmActionModal, useConfirmModalState } from 'client/modals/ConfirmModal';
import { useParams, NavLink } from 'react-router-dom';
import { produceSetMap } from 'client/utils/react';
import { Card, Nav, Tab, Badge, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useQuery, useMutation, keepPreviousData } from '@tanstack/react-query';
import { IReportQueueWithRelations } from 'client/reportQueue/types';
import moment from 'moment';
import { useDebounce } from 'use-debounce';
import ErrorAlert from 'client/components/ErrorAlert';
import useTableSelectRows, { SetSelectedRowsFn, SelectedRows } from 'client/hooks/useTableSelectRows';
import useTableSorting, { OrderDirection } from 'client/hooks/useTableSorting';
import ReportQueueTableFilterForm from 'client/reportQueue/ReportQueueTableFilterForm';
import useTablePagination from 'client/hooks/useTablePagination';
import useQueryClientUtils from 'client/hooks/useQueryClientUtils';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import { useSessionStorage } from 'client/hooks/useStorage';
import ReportQueueTable, { ReportQueueTableProps } from 'client/reportQueue/ReportQueueTable';
import ReportQueueHandledTable from 'client/reportQueue/ReportQueueHandledTable';
import {Helmet} from 'react-helmet';
import PageHeader from 'client/components/PageHeader';
import DownloadFilesModal from 'client/modals/DownloadFilesModal';
import { useModalState } from 'client/hooks/useModalState';
import * as ReportQueueFormatters from 'client/reportQueue/ReportQueueFormatters';
import { UserAnchor } from 'client/user/UserFormatters';
import * as IndustryReportFormatters from 'client/industryReport/IndustryReportFormatters';
import { CustomerAnchor } from 'client/customer/CustomerFormatters';
import DateFormat from 'client/components/DateFormat';
import NumberFormat from 'client/components/NumberFormat';
import * as CreditReportFormatters from 'client/creditReport/CreditReportFormatters';
import { CompanyAnchor } from 'client/company/CompanyFormatters';
import { BooleanWithIconFormatter } from 'client/components/MiscFormatters';
import * as commonColumnDefinitions from 'client/table/commonColumnDefinitions';
import { ColumnDefinition } from 'client/table/types';
import { Mail, Printer } from 'react-feather';
import useAuth from 'client/hooks/useAuth';
import { TableProvider } from 'client/contexts/TableContext';

export interface IFilterParams {
  order_by?: string;
  order_direction?: 'asc' | 'desc';
  production_date?: string;
  report_type?: string;
  report_org_number?: string;
  language?: string;
  order_number?: string;
  customer_id_exists?: boolean;
  customer_id?: string;
  created_at_from?: string;
  created_at_to?: string;
  deliver_email?: boolean;
  deliver_printed?: boolean;
  delivery?: 'email' | 'printed' | 'email_and_printed';
  production_date_from?: string;
  production_date_to?: string;
  search_term?: string;
}

interface IListData {
  list: IReportQueueWithRelations[];
  count?: number;
}

interface IBatchMutationVars {
  ids: number[];
  actions: string[];
}

interface IBatchMutationResponse {
  downloadUrls: {
    id: number;
    fileName: string;
    url: string;
  }[];
}

type TReportQueueTablePageTab = 'new' | 'waiting' | 'error' | 'ready_not_done' | 'ready_done';

interface IReportTableBatchFormData {
  production_date: string;
}

export interface IReportTableBatchForm {
  setIsDone?: boolean;
  setIsNotDone?: boolean;
  download?: boolean;
  emailCustomer?: boolean;
  setProductionDate?: boolean;
  data?: IReportTableBatchFormData;
}

export interface ReportQueueTablePageState {
  batchForm: IReportTableBatchForm;
  filterParams: IFilterParams;
  filterParamsHandled: IFilterParams;
  orderBy?: keyof IReportQueueWithRelations;
  orderDirection?: OrderDirection;
  filterVisibility: Partial<Record<keyof IFilterParams, boolean>>;
  isEditingFilterVisibility: boolean;
  selectedRowsByTab: Partial<Record<TReportQueueTablePageTab, SelectedRows>>;
  isExpanded: boolean;
}

interface IReportQueueTablePageTempState {
  isExecutingBatch: boolean;
  isExecutingBatchDelete: boolean;
}

interface IReportQueueWithRelationsWithId extends Omit<IReportQueueWithRelations, 'id'> {
  id: string;
}

export default React.memo(function ReportQueueTablePage () {
  const { tab = 'ready_not_done' } = useParams<{tab: TReportQueueTablePageTab}>();

  const defaultFilterParams: IFilterParams = useMemo(() => ({
    customer_id_exists: true,
  }), []);

  const [state, setState] = useSessionStorage<ReportQueueTablePageState>('ReportQueueTablePage', {
    orderBy: 'status_date',
    orderDirection: OrderDirection.ASC,
    batchForm: {...getDefaultBatchForm(), setIsDone: true},
    filterParams: {...defaultFilterParams},
    filterVisibility: {
      search_term: true,
      order_number: true,
      report_type: true,
      customer_id_exists: true,
      delivery: true,
    },
    isEditingFilterVisibility: false,
    filterParamsHandled: {
      production_date_from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      production_date_to: moment().format('YYYY-MM-DD'),
    },
    selectedRowsByTab: {},
    isExpanded: false,
  });

  const [columnsVisible, setColumnsVisible] = useState<string[]>(allTableColumns);
  const setStateMap = useCallback((updates: Record<string, any>) => {
    setState(produceSetMap(updates));
  }, [setState]);

  const downloadFilesStatusModalState = useModalState<typeof DownloadFilesModal>();

  // stuff we don't want to commit to session storage
  const [tempState, setTempState] = useState<IReportQueueTablePageTempState>({
    isExecutingBatch: false,
    isExecutingBatchDelete: false,
  });

  const [debouncedFilterParams] = useDebounce(state.filterParams, 500);
  const [debouncedFilterParamsHandled] = useDebounce(state.filterParamsHandled, 500);
  const { confirmAction:confirmDeleteMultipleAction, props:deleteMultipleModalProps } = useConfirmModalState();

  const tablePagination = useTablePagination();
  const tableSort = useTableSorting({
    initialOrderBy: state.orderBy,
    initialOrderDirection: state.orderDirection,
    onChange: (orderBy, orderDirection) => {
      setStateMap({orderBy, orderDirection});
    },
  });

  useEffect(() => {
    tablePagination.onChangePage(1);
  }, [
    // stuff for which we need to go back to page 1 when it changes
    tableSort.currentOrderBy,
    tableSort.currentOrderDirection,
    state.filterParams,
    state.filterParamsHandled,
  ]);

  const listQuery = useQuery<IListData, Error>({
    queryKey: [
      'ReportQueueTablePage',
      debouncedFilterParams,
      tableSort.params,
    ],
    placeholderData: keepPreviousData,
    queryFn: ({signal}) => axios.get('/api/report_queue', {
      signal,
      params: {
        ...debouncedFilterParams,
        ...tableSort.params,
      },
    }).then(response => ({list: response.data})),
  });
  const list = useMemo(() => listQuery.data?.list || [], [listQuery.data?.list]);

  const listQueryHandled = useQuery<IListData>({
    enabled: tab === 'ready_done',
    placeholderData: keepPreviousData,
    queryKey: [
      'ReportQueueTablePageHandled',
      debouncedFilterParams,
      debouncedFilterParamsHandled,
      tableSort.params,
      tablePagination.currentPage,
      tablePagination.rowsPerPage,
    ],
    queryFn: ({signal}) => axios.get('/api/report_queue/handled', {
      signal,
      params: {
        ...debouncedFilterParams,
        ...debouncedFilterParamsHandled,
        ...tableSort.params,
        page: tablePagination.currentPage,
        limit: tablePagination.rowsPerPage,
      },
    }).then(response => ({
      count: parseInt(response.headers['x-count'], 10),
      list: response.data,
    })),
  });
  const listHandled = useMemo(() => listQueryHandled.data?.list || [], [listQueryHandled.data?.list]);

  useEffect(() => {
    if (listQueryHandled.data) {
      const { count = 0 } = listQueryHandled.data;
      tablePagination.onChangeTotalCountOfRows(count);
    }
  }, [listQueryHandled.data]);

  const queryClientUtils = useQueryClientUtils();

  const onReportQueueDeleted = useCallback((id: number) => {
    listQuery.refetch();
    listQueryHandled.refetch();
    const options = {subKey: 'list'};
    queryClientUtils.applyDeletionsById(['ReportQueueTablePage'], [id], options);
    queryClientUtils.applyDeletionsById(['ReportQueueTablePageHandled'], [id], options);
  }, [
    queryClientUtils.applyDeletionsById,
    listQuery,
    listQueryHandled,
  ]);

  const onReportQueueUpdated = useCallback((id: number, update: IReportQueueWithRelations) => {
    queryClientUtils.applyUpdates(['ReportQueueTablePage'], [update], {subKey: 'list'});
    queryClientUtils.applyUpdates(['ReportQueueTablePageHandled'], [update], {subKey: 'list'});
  }, [queryClientUtils.applyUpdates]);

  const groups = useMemo(() => {
    return {
      ready_not_done: list.filter(item => item.status === 'READY'),
      error: list.filter(item => item.status === 'ERROR'),
      waiting: list.filter(item => item.status === 'WAITING'),
      new: list.filter(item => item.status === 'NEW'),
    };
  }, [list]);

  const batchMutation = useMutation<IBatchMutationResponse, Error, IBatchMutationVars>({
    mutationFn: vars => axios.post('/api/report_queue/batch', vars).then(r => r.data),
    onMutate: () => {
      setTempState(p => ({...p, isExecutingBatch: true}));
    },
    onSettled: () => {
      setTempState(p => ({...p, isExecutingBatch: false}));
    },
    onSuccess: (data, vars) => {
      const shouldDownload = vars.actions.includes('download');
      if (shouldDownload) {
        const { downloadUrls } = data;

        const files = vars.ids.map(id => {
          const downloadItem = downloadUrls.find(item => item.id === id);
          // lol hacky way to find the report. we don't know if it should be in list or listHandled so try both
          const report = list.find(report => report.id === id) || listHandled.find(report => report.id === id);
          return {
            id,
            ...(downloadItem || {}),
            render: () => {
              if (!report) return <>-</>;
              return <ReportQueueFormatters.ReportQueueAnchor value={report} target="_blank" />;
            },
          };
        });

        downloadFilesStatusModalState.onShow({files});
      }

      const refetchRequired = !(shouldDownload && vars.actions.length === 1);
      if (refetchRequired) {
        listQuery.refetch();
        listQueryHandled.refetch();
      }

      setStateMap({selectedRowsByTab: {}, batchForm: getDefaultBatchForm()});
    },
  });

  const onExecuteBatch = useCallback((reportQueueIds: number[], form: IReportTableBatchForm) => {
    const { data, ...actions } = form;
    const vars = {
      ids: reportQueueIds,
      actions: Object.keys(actions).filter(key => (form as any)[key]),
      data,
    };
    return batchMutation.mutateAsync(vars);
  }, [batchMutation]);

  const batchDeleteMutation = useMutation<IBatchMutationResponse, Error, IBatchMutationVars>({
    mutationFn: vars => axios.post('/api/report_queue/batch', vars).then(r => r.data),
    onMutate: () => {
      setTempState(p => ({...p, isExecutingBatchDelete: true}));
    },
    onSettled: () => {
      setTempState(p => ({...p, isExecutingBatchDelete: false}));
    },
    onSuccess: data => {
      requestCallbacks.onSuccess('Rapportköerna har raderats');
      listQuery.refetch();
      listQueryHandled.refetch();
      setStateMap({selectedRowsByTab: {}, batchForm: getDefaultBatchForm()});
    },
  });

  const onExecuteBatchDelete = useCallback((reportQueueIds: number[]) => {
    const vars = {ids: reportQueueIds, actions: ['delete']};
    return confirmDeleteMultipleAction(() => batchDeleteMutation.mutateAsync(vars));
  }, [confirmDeleteMultipleAction, batchDeleteMutation]);

  const onRefetch = useCallback(() => {
    if (tab === 'ready_done') listQueryHandled.refetch();
    listQuery.refetch();
  }, [listQueryHandled, listQuery]);

  const onToggleExpand = useCallback(() => {
    setStateMap({isExpanded: !state.isExpanded });
  }, [setStateMap, state.isExpanded]);

  const allIds = useMemo(() => {
    const rows = tab === 'ready_done' ? listHandled : groups[tab];
    return rows.map(item => item.id) || [];
  }, [list, listHandled, tab]);
  const selectedRows = useMemo(() => state.selectedRowsByTab[tab] || {}, [tab, state.selectedRowsByTab]);
  const setSelectedRows: SetSelectedRowsFn = useCallback((value) => setStateMap({
    [`selectedRowsByTab.${tab}`]: typeof value === 'function' ? value(selectedRows || {}) : value,
  }), [selectedRows, setStateMap]);
  const tableSelectRows = useTableSelectRows(allIds, selectedRows, setSelectedRows);
  const auth = useAuth();
  const error = listQuery.error || listQueryHandled.error || batchMutation.error || batchDeleteMutation.error;
  const batchFormHidden = !auth.isUserRole(['admin', 'coordinator']);
  const columnDefinitions = useMemo(() => columnDefinitionFactory(state.isExpanded, batchFormHidden), [
    state.isExpanded,
    batchFormHidden,
  ]);

  const tableProps: Omit<ReportQueueTableProps, 'variant' | 'list'> = useMemo(() => ({
    state,
    setStateMap,
    onExecuteBatch,
    onExecuteBatchDelete,
    onReportQueueDeleted,
    onReportQueueUpdated,
    isExecutingBatch: tempState.isExecutingBatch,
    isExecutingBatchDelete: tempState.isExecutingBatchDelete,
    isLoading: listQuery.isRefetching,
    isInitialLoading: listQuery.isLoading,
    isSuccess: listQuery.isSuccess,
    tableSort,
    columns: columnDefinitions as any[],
    tableSelectRows,
    columnsVisible: state.isExpanded ? columnsVisible : defaultColumnsVisible,
  }), [
    state,
    setStateMap,
    onExecuteBatch,
    onExecuteBatchDelete,
    onReportQueueDeleted,
    onReportQueueUpdated,
    tempState.isExecutingBatch,
    tempState.isExecutingBatchDelete,
    listQuery.isLoading,
    listQuery.isRefetching,
    listQuery.isSuccess,
    tableSort,
    columnDefinitions,
    columnsVisible,
    tableSelectRows.selectedRows,
  ]);

  return (
    <PageContainer fluid>
      <TableProvider tableSelectRows={tableSelectRows} tableSort={tableSort}>
        <Helmet>
          <title>Rapportkö</title>
        </Helmet>
        <PageHeader>Rapportkö</PageHeader>
        <ConfirmActionModal
          {...deleteMultipleModalProps}
          message="Är du säker på att du vill radera de markerade rapporterna?"
        />
        {downloadFilesStatusModalState.mounted && (
          <DownloadFilesModal {...downloadFilesStatusModalState} />
        )}
        <ErrorAlert className="my-3" error={error} />
        <Card>
          <Card.Header className="p-0">
            <ReportQueueTableFilterForm
              isLoading={tableProps.isLoading}
              filterParams={state.filterParams}
              setFilterParams={filterParams => setStateMap({filterParams})}
              defaultFilterParams={defaultFilterParams}
              filterVisibility={state.filterVisibility}
              setFilterVisibility={filterVisibility => setStateMap({filterVisibility})}
              isEditingFilterVisibility={state.isEditingFilterVisibility}
              setIsEditingFilterVisibility={isEditingFilterVisibility => setStateMap({isEditingFilterVisibility})}
              onRefetch={onRefetch}
              onToggleIsExpanded={onToggleExpand}
              isExpanded={state.isExpanded}
              columnDefinitions={columnDefinitions}
              columnsVisible={columnsVisible}
              setColumnsVisible={setColumnsVisible}
            />
          </Card.Header>
          <Tab.Container
            defaultActiveKey="ready_not_done"
            onSelect={tab => setStateMap({tab: tab})}
            activeKey={tab}
            transition={false}
            mountOnEnter
            unmountOnExit
          >
            <Nav className="nav-tabs pt-3 px-3">
              <ReportTablePageTabLink
                eventKey="new"
                title="Väntar på datum"
                list={groups.new}
              />
              <ReportTablePageTabLink
                eventKey="waiting"
                title="Väntar på bokslut"
                list={groups.waiting}
              />
              <ReportTablePageTabLink
                eventKey="error"
                title="Problem"
                list={groups.error}
              />
              <ReportTablePageTabLink
                eventKey="ready_not_done"
                title="Ej hanterade"
                list={groups.ready_not_done}
              />
              <ReportTablePageTabLink
                eventKey="ready_done"
                title="Hanterade"
              />
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="new">
                <Helmet>
                  <title>Rapportkö - Väntar på datum</title>
                </Helmet>
                <ReportQueueTable
                  {...tableProps}
                  variant="new"
                  list={groups.new}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="waiting">
                <Helmet>
                  <title>Rapportkö - Väntar på bokslut</title>
                </Helmet>
                <ReportQueueTable
                  {...tableProps}
                  variant="waiting"
                  list={groups.waiting}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="error">
                <Helmet>
                  <title>Rapportkö - Problem</title>
                </Helmet>
                <ReportQueueTable
                  {...tableProps}
                  variant="error"
                  list={groups.error}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="ready_not_done">
                <Helmet>
                  <title>Rapportkö - Ej hanterade</title>
                </Helmet>
                <ReportQueueTable
                  {...tableProps}
                  variant="ready_not_done"
                  list={groups.ready_not_done}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="ready_done">
                <Helmet>
                  <title>Rapportkö - Hanterade</title>
                </Helmet>
                <ReportQueueHandledTable
                  {...tableProps}
                  list={listHandled}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Card>
      </TableProvider>
    </PageContainer>
  );
});

interface IReportTablePageTabLink {
  eventKey: string;
  title: string;
  list?: IReportQueueWithRelations[];
}

const ReportTablePageTabLink: React.FC<IReportTablePageTabLink> = React.memo(function ReportTablePageTabLink (props: IReportTablePageTabLink) {
  const { eventKey, title, list } = props;
  return (
    <Nav.Item>
      <Nav.Link as={NavLink} to={`/report_queue/${eventKey}`}>
        {title}
        {list && list.length > 0 && (
          <Badge className="ms-1">{list.length}</Badge>
        )}
      </Nav.Link>
    </Nav.Item>
  );
});

function getDefaultBatchForm () {
  return {
    setIsDone: false,
    setIsNotDone: false,
    download: false,
    emailCustomer: false,
    setProductionDate: false,
  };
}

const allTableColumns: string[] = [
  'select',
  'id',
  'status',
  'status_date',
  'is_emailed',
  'is_downloaded',
  'deliver_email',
  'cs_basic.company_name',
  'report_org_number',
  'customer_id',
  'customer.email',
  'credit_report',
  'credit_report.created_at',
  'credit_report.kf_sum_ansokan',
  'credit_report.kf_sum_anm',
  'credit_report.kf_debt',
  'order_number',
  'comment',
  'report_type',
  'valuation_report',
  'industry_report',
  'production_date',
  'report_settings',
  'created_by_user_id',
  'created_at',
  'actions',
];

const defaultColumnsVisible: string[] = [
  'select',
  'id',
  'status',
  'report_org_number',
  'customer_id',
  'order_number',
  'report_type',
  'credit_report',
  'production_date',
  'created_at',
  'report_settings',
  'actions',
];

const columnSortable: string[] = [
  'id',
  'status',
  'status_date',
  'is_emailed',
  'is_downloaded',
  'report_org_number',
  'customer_id',
  'order_number',
  'report_type',
  'production_date',
  'created_by_user_id',
  'created_at',
];

type ColumnDefinitionFactory = (isExpanded: boolean, isBatchActionsShown: boolean) => ColumnDefinition<IReportQueueWithRelationsWithId>[];

const columnDefinitionFactory: ColumnDefinitionFactory = (isExpanded, batchFormHidden) => [
  {
    ...commonColumnDefinitions.select(),
    show: !batchFormHidden,
  },
  commonColumnDefinitions.cell(['id', 'ID'], props => (
    <ReportQueueFormatters.ReportQueueAnchor value={props.row} />
  )),
  {
    id: 'status',
    label: 'Status',
    meta: isExpanded ? {sortKey: 'status'} : {sortKey: 'status_date'},
    Body: props => {
      const row = props.row as unknown as IReportQueueWithRelations;
      return (
        <>
          {isExpanded ? (
            <ReportQueueFormatters.StatusLabel value={row.status} done={row.is_done} />
          ) : (
            <>
              <span>
                <ReportQueueFormatters.StatusLabel value={row.status} done={row.is_done} />{' '}
                {row.is_emailed && <span title="Mailad"><Mail size={18} /></span>}
                {' '}
                {row.is_downloaded && <span title="Nedladdad"><Printer size={18} /></span>}
              </span>
              <small className="d-block"><DateFormat value={row.status_date} /></small>
            </>
          )}
        </>
      );
    },
  } as ColumnDefinition<IReportQueueWithRelationsWithId>,
  commonColumnDefinitions.cell(['status_date', 'Datum'], props => (
    <small><DateFormat value={props.row.status_date} /></small>
  )),
  commonColumnDefinitions.cell(['is_emailed', 'Mailad'], props => (
    <BooleanWithIconFormatter value={props.row.is_emailed} />
  )),
  commonColumnDefinitions.cell(['is_downloaded', 'Nedladdad'], props => (
    <BooleanWithIconFormatter value={props.row.is_downloaded} />
  )),
  commonColumnDefinitions.cell(['deliver_email', 'Leverans'], props => (
    <ReportQueueFormatters.DeliveryPreference
      deliverEmail={props.row.deliver_email}
      deliverPrinted={props.row.deliver_printed}
    />
  )),
  commonColumnDefinitions.cell(['cs_basic.company_name', 'Företagsnamn'], props => (
    <>
      {props.row.cs_basic?.company_name && (
        <>
          {props.row.cs_basic.company_name}
          <br />
        </>
      )}
    </>
  )),
  commonColumnDefinitions.cell(['report_org_number', isExpanded ? 'Orgnr' : 'Företag'], props => (
    <>
      {!isExpanded && props.row.cs_basic?.company_name && (
        <>
          {props.row.cs_basic.company_name}
          <br />
        </>
      )}
      {props.row.report_org_number && (
        <CompanyAnchor value={props.row.report_org_number} />
      )}
    </>
  )),
  commonColumnDefinitions.cell(['customer_id', 'Kund'], props => (
    <>
      {props.row.customer ? (
        <>
          <CustomerAnchor value={props.row.customer} /><br />
          {!isExpanded && (
            <small>
              {props.row.customer.id}{' '}
              {!props.row.customer.email && (
                <span className="text-danger">(Mailadress saknas)</span>
              )}
            </small>
          )}
        </>
      ) : '-'}
    </>
  )),
  commonColumnDefinitions.cell(['customer.email', 'Har e-post?'], props => (
    <BooleanWithIconFormatter value={Boolean(props.row.customer?.email)} />
  )),
  commonColumnDefinitions.cell(['credit_report', 'Kreditupplysning'], props => props.row.credit_report && (
    <>
      {props.row.credit_report && (
        <CreditReportFormatters.CreditReportLoadingModalButton value={props.row.credit_report?.id} />
      )}
      {!isExpanded && props.row.credit_report && (
        <small className="d-block"><DateFormat value={props.row.credit_report.created_at} /></small>
      )}
    </>
  )),
  commonColumnDefinitions.cell([
    'credit_report.created_at',
    'K.U. Datum',
    'Kreditupplysningens datum skapad',
  ], props => (
    <>
      {props.row.credit_report && (
        <small><DateFormat value={props.row.credit_report.created_at} /></small>
      )}
    </>
  )),
  commonColumnDefinitions.cell([
    'credit_report.kf_sum_ansokan',
    'K.U. B.F.',
    'Kreditupplysningens summa betalningsförelägganden',
  ], props => (
    <>
      {props.row.credit_report && (
        <CreditReportFormatters.SumAnsokan value={props.row.credit_report} />
      )}
    </>
  )),
  commonColumnDefinitions.cell([
    'credit_report.kf_debt',
    'K.U. K.F.',
    'Kreditupplysningens summa kronofogden',
  ], props => (
    <>
      {props.row.credit_report && (
        <CreditReportFormatters.KfDebt value={props.row.credit_report} />
      )}
    </>
  )),
  commonColumnDefinitions.cell([
    'credit_report.kf_sum_anm',
    'K.U. B.A.',
    'Kreditupplysningens summa betalningsanmärkningar',
  ], props => (
    <>
      {props.row.credit_report && (
        <CreditReportFormatters.SumAnm value={props.row.credit_report} />
      )}
    </>
  )),
  commonColumnDefinitions.cell(['order_number', 'Order'], props => (
    <>
      {isExpanded ? (
        props.row.order_number || '-'
      ): (
        <>
          {props.row.comment ? (
            <OverlayTrigger placement="bottom" overlay={<Tooltip>{props.row.comment}</Tooltip>}>
              <a className="small d-block">{props.row.order_number || '-'}</a>
            </OverlayTrigger>
          ) : (
            <>{props.row.order_number || '-'}</>
          )}
          <small className="d-block">
            Lev:{' '}
            <ReportQueueFormatters.DeliveryPreference
              deliverEmail={props.row.deliver_email}
              deliverPrinted={props.row.deliver_printed}
            />
          </small>
        </>
      )}
    </>
  )),
  commonColumnDefinitions.cell(['report_type', 'Typ'], props => (
    <>
      <ReportQueueFormatters.ReportsType
        type={props.row.report_type}
        language={props.row.language}
        isGroup={props.row.report_settings.company_use_group_accounts}
      />{' '}
      {!isExpanded && props.row.report_type === 'valuation_report' && props.row.valuation_report && (
        <small className="d-block">
          <NumberFormat value={props.row.valuation_report.report_valuation ?? 0} format="currency" />
        </small>
      )}
      {!isExpanded && props.row.report_type === 'industry_report' && props.row.industry_report && (
        <IndustryReportFormatters.IndustryReportCompanyCounts value={props.row.industry_report} />
      )}
    </>
  )),
  commonColumnDefinitions.cell(['comment', 'Kommentar'], props => (
    <>
      {props.row.comment && (
        <OverlayTrigger placement="bottom" overlay={<Tooltip style={{ position: 'fixed' }}>{props.row.comment}</Tooltip>}>
          <a className="small">Kommentar</a>
        </OverlayTrigger>
      )}
    </>
  )),
  commonColumnDefinitions.cell(['valuation_report', 'Värde'], props => (
    <>
      {props.row.report_type === 'valuation_report' && props.row.valuation_report ? (
        <NumberFormat value={props.row.valuation_report.report_valuation ?? 0} format="currency" />
      ) : '-'}
    </>
  )),
  commonColumnDefinitions.cell(['industry_report', 'Antal företag'], props => (
    <>
      {props.row.report_type === 'industry_report' && props.row.industry_report ? (
        <IndustryReportFormatters.IndustryReportCompanyCounts
          title=""
          value={props.row.industry_report}
        />
      ) : '-'}
    </>
  )),
  commonColumnDefinitions.cell(['created_by_user_id', 'Skapare'], props => (
    <>
      {props.row.creator ? <UserAnchor value={props.row.creator} /> : '-'}
    </>
  )),

  commonColumnDefinitions.cell(['production_date', 'Produktionsdatum'], props => (
    <>
      {isExpanded ? (
        <small><DateFormat value={props.row.production_date} format="YYYY-MM-DD" /></small>
      ) : (
        <DateFormat value={props.row.production_date} format="YYYY-MM-DD" />
      )}
    </>
  )),
  commonColumnDefinitions.cell(['created_at', 'Skapad'], props => (
    <>
      {!isExpanded && props.row.creator && <UserAnchor value={props.row.creator} />}
      <small className="d-block">
        <DateFormat value={props.row.created_at} format="YYYY-MM-DD" />
      </small>
    </>
  )),
  commonColumnDefinitions.cell(['report_settings', 'Bokslut'], props => (
    <>
      {props.row.report_settings?.accounts_from && props.row.report_settings?.accounts_to ? (
        <ReportQueueFormatters.AccountInterval
          className="d-block small"
          value={props.row}
        />
      ) : '-'}
    </>
  )),

  commonColumnDefinitions.actions(undefined, isExpanded ? {size: 'sm', iconSize: 12} : undefined),
].map(obj => ({
  ...obj,
  show: defaultColumnsVisible.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? commonColumnDefinitions.sortable(obj) : obj);
