import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card } from 'react-bootstrap';
import { TableSpinningOverlay } from 'client/table/TableUtils';
import RefreshButton from 'client/buttons/RefreshButton';
import axios from 'client/axios';
import classNames from 'classnames';
import ErrorAlert from 'client/components/ErrorAlert';
import { AnnualReportDownloadButton } from 'client/annualReport/AnnualReportButtons';
import { AnnualReportWithRelationsRow, AnnualReportRow } from 'client/annualReport/types';
import * as AnnualReportFormatters from 'client/annualReport/AnnualReportFormatters';
import LoadingButton from 'client/buttons/LoadingButton';
import TableSettingsButton from 'client/table/TableSettingsButton';
import TableVirtual from 'client/table/TableVirtual';
import { ColumnDefinition } from 'client/table/types';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import { TableProvider } from 'client/contexts/TableContext';

interface CompanyAnnualReportTableProps {
  orgNumber: string;
  className?: string;
}

interface AnnualReportListResponse {
  rows: AnnualReportWithRelationsRow[];
  total_rows: number;
}

export default function CompanyAnnualReportTable (props: CompanyAnnualReportTableProps) {
  const { className, orgNumber } = props;

  const query = useQuery<AnnualReportListResponse>({
    queryKey: ['/api/annual_report/list', {
      org_number: orgNumber,
      order: '-date_to',
    }],
  });

  const fetchMutation = useMutation({
    mutationFn: async () => {
      await axios.post<AnnualReportRow[]>(`/api/annual_report/company/${orgNumber}/fetch`);
      await query.refetch();
    },
  });

  const onClickFetch = () => {
    return fetchMutation.mutateAsync();
  };

  const onReload = query.refetch;

  const list = query.data?.rows ?? [];
  const error = query.error;

  const columnDefinitions = useMemo(columnDefinitionFactory, []);

  const [columnsVisible, setColumnsVisible] = useState<string[]>(() => columnDefinitions.filter(c => c.show).map(c => c.id));

  return (
    <TableProvider onReload={onReload}>
      <Card className={classNames(className, 'border-0')}>
        <Card.Header className="border border-bottom-0 px-3 py-2">
          <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center gap-2">
            <span className="flex-grow-1">Företagets årsredovisningar</span>
            <TableSettingsButton
              className="py-0"
              columnDefinitions={columnDefinitions}
              columnsVisible={columnsVisible}
              setColumnsVisible={setColumnsVisible}
              size="sm"
            />
            <RefreshButton
              onClick={onReload}
              disabled={query.isLoading || query.isRefetching}
              className="p-1"
              size="sm"
            />
          </Card.Title>
        </Card.Header>
        {error && (
          <Card.Body className="border border-bottom-0">
            <ErrorAlert className="mb-0" error={error} />
          </Card.Body>
        )}
        <TableSpinningOverlay isLoading={query.isRefetching}>
          <div className="table-responsive">
            <TableVirtual
              className="mb-0 border align-middle"
              rows={list}
              columns={columnDefinitions}
              columnsVisible={columnsVisible}
              isInitialLoading={query.isLoading}
            />
          </div>
        </TableSpinningOverlay>
        <Card.Footer className="d-flex flex-wrap align-items-center border border-top-0 p-2">
          <ErrorAlert error={fetchMutation.error} className="w-100 mb-2" />
          <LoadingButton
            variant="primary"
            size="sm"
            isLoading={fetchMutation.isPending}
            onClick={onClickFetch}
          >
            Uppdatera lista
          </LoadingButton>
        </Card.Footer>
      </Card>
    </TableProvider>
  );
}

type ColumnDefinitionFactory = () => ColumnDefinition<AnnualReportWithRelationsRow>[];

const columnDefinitionFactory: ColumnDefinitionFactory = () => [
  columnDefs.cell(['id', 'ID'], props => (
    <AnnualReportFormatters.Id value={props.row.id} />
  )),
  columnDefs.simple(['company_name', 'Företagsnamn']),
  columnDefs.cell(['document_type', 'Dokumenttyp'], props => (
    <AnnualReportFormatters.DocumentType value={props.row.document_type} />
  )),
  columnDefs.date(['date_from', 'Från']),
  columnDefs.date(['date_to', 'Till']),
  columnDefs.actions(props => {
    const { row } = props;
    return (
      <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
        <AnnualReportDownloadButton
          variant="outline-primary"
          annualReport={row}
          size="sm"
        />
      </div>
    );
  }),
].map(obj => ({
  ...obj,
  show: true,
}));
