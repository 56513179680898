import React, { useCallback, useRef, useState, useId, useMemo } from 'react';
import { Settings } from 'react-feather';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import { ColumnDefinition, FilterDefinition } from 'client/table/types'; 
import classNames from 'classnames';
import { Modal, Form, ModalProps, Button, Row, Col } from 'react-bootstrap';
import { useDrop, useDrag } from 'react-dnd';
import { move } from 'formik';
import { cloneDeep } from 'lodash';

export interface TableSettingsButtonProps {
  columnDefinitions?: ColumnDefinition<any>[];
  columnsVisible?: string[];
  setColumnsVisible?: React.Dispatch<React.SetStateAction<string[]>>;

  filterDefinitions?: FilterDefinition<any>[];
  filtersVisible?: string[];
  setFiltersVisible?: React.Dispatch<React.SetStateAction<string[]>>;

  defaultColumnsVisible?: string[];
  defaultFiltersVisible?: string[];

  className?: string;
  size?: 'sm' | 'lg';
  disabled?: boolean;
}

export default function TableSettingsButton (props: TableSettingsButtonProps) {
  const {
    className,
    columnsVisible,
    setColumnsVisible,
    columnDefinitions,
    filterDefinitions,
    filtersVisible,
    setFiltersVisible,
    defaultColumnsVisible,
    defaultFiltersVisible,
    size,
    disabled,
  } = props;

  let iconSize = 18, paddingClassName = '';
  if (size === 'sm') {
    paddingClassName = 'px-1';
    iconSize = 12;
  }
  if (size === 'lg') {
    paddingClassName = 'px-2';
    iconSize = 24;
  }

  const onSubmit = (columns: Selectable[], filters: Selectable[]) => {
    const newColumnOrder = columns.filter(c => c.show).map(c => c.id);
    const newFilterOrder = filters.filter(f => f.show).map(f => f.id);
    setColumnsVisible?.(newColumnOrder);
    setFiltersVisible?.(newFilterOrder);
  };

  const columnsSelectable = columnDefinitions ? definitionsToSelectables(columnDefinitions, columnsVisible || []) : [];
  const filtersSelectable = filterDefinitions ? definitionsToSelectables(filterDefinitions, filtersVisible || []) : [];

  const modalProps = {
    onSubmit,
    columnsSelectable,
    filtersSelectable,
    defaultColumnsVisible,
    defaultFiltersVisible,
  };

  return (
    <ModalOpeningButton
      title="Visa tabellinställningar"
      Modal={TableSettingsModal}
      modalProps={modalProps}
      variant="outline-primary"
      size={size}
      className={classNames(paddingClassName, 'd-flex gap-1 align-items-center position-relative', className)}
      disabled={disabled}
    >
      <Settings size={iconSize} /> Inställningar
    </ModalOpeningButton>
  );
}

interface TableSettingsModalProps extends ModalProps {
  onSubmit: (columns: Selectable[], filters: Selectable[]) => void;
  columnsSelectable: Selectable[];
  filtersSelectable: Selectable[];
  defaultColumnsVisible?: string[];
  defaultFiltersVisible?: string[];
}

function TableSettingsModal (props: TableSettingsModalProps) {
  const {
    show,
    onHide,
    onExited,
    onSubmit,
    columnsSelectable,
    filtersSelectable,
    defaultColumnsVisible,
    defaultFiltersVisible,
  } = props;

  const [filters, setFilters] = useState<Selectable[]>(cloneDeep(filtersSelectable));
  const [columns, setColumns] = useState<Selectable[]>(cloneDeep(columnsSelectable));
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');

  const onChangeColumnPosition = useCallback((from: number, to: number) => {
    setColumns(prev => move(prev, from, to) as Selectable[]);
    setIsDirty(true);
  }, [setColumns, setIsDirty]);

  const onChangeFilterPosition = useCallback((from: number, to: number) => {
    setFilters(prev => move(prev, from, to) as Selectable[]);
    setIsDirty(true);
  }, [setFilters, setIsDirty]);

  const onToggleColumnVisibility = useCallback((id: string) => {
    setColumns(prev => prev.map(c => ({
      ...c,
      show: c.id === id ? !c.show : c.show,
    })));
    setIsDirty(true);
  }, [setColumns, setIsDirty]);

  const onToggleFilterVisibility = useCallback((id: string) => {
    setFilters(prev => prev.map(c => ({
      ...c,
      show: c.id === id ? !c.show : c.show,
    })));
    setIsDirty(true);
  }, [setFilters, setIsDirty]);

  const onClickSave = useCallback(() => {
    onSubmit(columns, filters);
    onHide?.();
  }, [onSubmit, onHide, columns, filters]);

  const onChangeSearch = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(ev.target.value);
  }, [setSearch]);

  const onClickResetSearch = useCallback(() => {
    setSearch('');
  }, [setSearch]);

  const foundColumns = useMemo(() => {
    return columns.filter(column => matchesSearch(column, search));
  }, [columns, search]);

  const foundFilters = useMemo(() => {
    return filters.filter(filter => matchesSearch(filter, search));
  }, [filters, search]);

  return (
    <Modal show={show} onHide={onHide} onExited={onExited} scrollable size="xl">
      <Modal.Header>
        <Modal.Title>
          Tabellinställningar
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-1">
        <div className="d-flex align-items-start gap-1">
          <Form.Group className="mb-3 flex-grow-1">
            <Form.Control
              placeholder="Ange en söksträng"
              name="search"
              value={search}
              onChange={onChangeSearch}
              size="sm"
            />
          </Form.Group>
          <Button
            variant="outline-primary"
            size="sm"
            disabled={!search}
            onClick={onClickResetSearch}
          >
            Töm
          </Button>
        </div>
        <Row>

          <Col>
            <SelectableHeader
              setSelected={setFilters}
              setIsDirty={setIsDirty}
              search={search}
              defaultIdsVisible={defaultFiltersVisible}
            >
              {search ? 'Matchande filter' : 'Alla filter'}
            </SelectableHeader>
            {foundFilters.length > 0 ? (
              <div className="d-flex flex-column gap-2 align-items-baseline p-0 pb-2">
                {foundFilters.map((filter, index) => (
                  <SortableBar
                    index={index}
                    key={filter.id}
                    selectable={filter}
                    dragDisabled={Boolean(search)}
                    onChangePosition={onChangeFilterPosition}
                    onToggleVisibility={onToggleFilterVisibility}
                  />
                ))}
              </div>
            ) : (
              <div className="mb-2">Inga filter matchar söksträngen</div>
            )}
          </Col>

          <Col>
            <SelectableHeader
              setSelected={setColumns}
              setIsDirty={setIsDirty}
              search={search}
              defaultIdsVisible={defaultColumnsVisible}
            >
              {search ? 'Matchande kolumner' : 'Alla kolumner'}
            </SelectableHeader>
            {foundColumns.length > 0 ? (
              <div className="d-flex flex-column gap-2 align-items-baseline p-0 pb-2">
                {foundColumns.map((column, index) => (
                  <SortableBar
                    index={index}
                    key={column.id}
                    selectable={column}
                    dragDisabled={Boolean(search)}
                    onChangePosition={onChangeColumnPosition}
                    onToggleVisibility={onToggleColumnVisibility}
                  />
                ))}
              </div>
            ) : (
              <div className="mb-2">Inga kolumner matchar söksträngen</div>
            )}
          </Col>

        </Row>
      </Modal.Body>
      <Modal.Footer className="p-2">
        <Button variant="outline-primary" size="sm" onClick={onHide}>
          Avbryt
        </Button>
        <Button
          type="button"
          size="sm"
          onClick={onClickSave}
          disabled={!isDirty}
        >
          Spara
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

interface SelectableHeaderProps extends React.PropsWithChildren {
  setSelected: React.Dispatch<React.SetStateAction<Selectable[]>>;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  search: string;
  defaultIdsVisible?: string[];
}

function SelectableHeader (props: SelectableHeaderProps) {
  const { children, setSelected, setIsDirty, defaultIdsVisible, search } = props;

  const onClickToggle: React.MouseEventHandler<HTMLButtonElement> = useCallback(ev => {
    setSelected(prev => prev.map(item => {
      if (!matchesSearch(item, search)) return item;
      return {...item, show: (ev.target as any).value === '1'};
    }));
    setIsDirty(true);
  }, [setIsDirty, setSelected, search]);

  const onClickReset: React.MouseEventHandler<HTMLButtonElement> = useCallback(ev => {
    setSelected(prev => prev.map(item => ({...item, show: (defaultIdsVisible || []).includes(item.id)})));
    setIsDirty(true);
  }, [setIsDirty, setSelected, search]);

  return (
    <h4 className="d-flex gap-2 flex-wrap align-items-center justify-content-between border-bottom pb-1 mb-3">
      {children}
      <div className="d-flex gap-2 flex-wrap align-items-center">
        <Button variant="outline-primary" size="sm" onClick={onClickToggle} value="1" className="py-0">
          Markera
        </Button>
        <Button variant="outline-primary" size="sm" onClick={onClickToggle} value="0" className="py-0">
          Avmarkera
        </Button>
        {defaultIdsVisible && (
          <Button variant="outline-primary" size="sm" onClick={onClickReset} value="0" className="py-0">
            Återställ
          </Button>
        )}
      </div>
    </h4>
  );
}

interface Selectable {
  id: string;
  label: string;
  description?: string;
  show: boolean;
}

interface SelectableDragObject {
  index: number;
}

interface SelectableDragCollectedProps {
  isDragging: boolean;
}

interface SelectableDropCollectedProps {
  handlerId: string | symbol | null;
}

interface SortableBarProps {
  selectable: Selectable;
  onChangePosition: (fromIndex: number, toIndex: number) => true | void;
  dragDisabled?: boolean;
  onToggleVisibility: (id: string) => void;
  index: number;
}

function SortableBar (props: SortableBarProps) {
  const { selectable, onChangePosition, dragDisabled = false, onToggleVisibility, index } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [dropProps, drop] = useDrop<SelectableDragObject, unknown, SelectableDropCollectedProps>({
    accept: 'selectable',
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (hoverItem, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragPosition = hoverItem.index || 0;
      const hoverPosition = index || 0;

      if (dragPosition === hoverPosition) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragPosition < hoverPosition && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragPosition > hoverPosition && hoverClientY > hoverMiddleY) {
        return;
      }
      
      const cancelled = onChangePosition(dragPosition, hoverPosition);
      if (!cancelled) {
        hoverItem.index = hoverPosition;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag<SelectableDragObject, unknown, SelectableDragCollectedProps>({
    type: 'selectable',
    // important to have a function here
    item: () => ({
      index,
    }),
    canDrag: () => !dragDisabled,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const className = classNames('d-flex w-100 align-items-center justify-content-start cursor-grab border rounded p-1 px-2', {
    'opacity-50': isDragging,
    'text-muted bg-light': !selectable.show,
  });

  const id = useId();

  const onChange = useCallback(() => {
    onToggleVisibility(selectable.id);
  }, [onToggleVisibility, selectable.id]);

  return (
    <div
      ref={ref}
      className={className}
      data-handler-id={dropProps.handlerId}
    >
      <Form.Check
        className="me-2"
        id={id}
        inline
        checked={Boolean(selectable.show)}
        onChange={onChange}
        label={<SelectableCheckLabel {...selectable} />}
      />
    </div>
  );
}

interface SelectableCheckLabelProps {
  label: string;
  description?: string;
}

function SelectableCheckLabel (props: SelectableCheckLabelProps) {
  const { label, description } = props;
  if (!description) return <>{label}</>;
  return (
    <span>
      <span className="d-block">{label}</span>
      <span className="d-block small text-muted">{description}</span>
    </span>
  );
}

function matchesSearch (obj: Selectable, search: string): boolean {
  const { id, label } = obj;
  if (!search) return true;
  const regexp = new RegExp(search, 'i');
  return regexp.test(id) || regexp.test(label || '');
}

function definitionsToSelectables (definitions: (FilterDefinition<any> | ColumnDefinition<any>)[], idsVisible: string[]): Selectable[] {
  const selected: Selectable[] = (idsVisible.map(itemId => {
    const def = definitions.find(def => def.id === itemId);
    if (!def) return null;
    const { id, label, description } = def;
    return { id, label, description, show: true };
  }).filter(v => v)) as Selectable[];

  return [
    ...selected,
    // everything not selected goes in the end
    ...definitions.filter(def => {
      return !selected.find(d => d.id === def.id);
    }).map(def => {
      const { id, label, description } = def;
      return {id, label: label ?? id, description, show: false};
    }),
  ];
}
