import React, { useId, useMemo, useEffect, useCallback } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FilterDefinition } from 'client/table/types';
import { TableFilterInputGroup } from 'client/table/TableUtils';
import CompanySearch, { useCompanySearchManager } from 'client/components/CompanySearch';
import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { IUser } from 'client/user/types';
import useFocusControl from 'client/hooks/useFocusControl';
import moment from 'moment';
import { isNull, defaultsDeep, omit } from 'lodash';
import {CompanyEventMetaWatchableItem} from 'client/companyEvent/types';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import { WatchEventTypesModal } from 'client/companyMonitored/CompanyMonitoredUtils';
import {CompanyMonitoredMeta} from 'client/companyMonitored/types';
import TableFilterSimpleModal, {TableFilterSimpleModalBodyProps} from 'client/table/TableFilterSimpleModal';
import InputFormatted from 'client/components/InputFormatted';

export type FilterIdentifier = [string] | [string, string] | [string, string, string];

// a simple string input
export const input = (identifier: FilterIdentifier, extendWith = {}): FilterDefinition<string> => ({
  id: identifier[0],
  label: identifier[1],
  description: identifier[2],
  Render: props => {
    const { id, label, description, size, value:outerValue, validate, onChange:onChangeOuter, extraProps = {} } = props;

    const {
      onFocus,
      onChange,
      onBlur,
      value,
    } = useFocusControl(outerValue ?? '', onChangeOuter);

    const onReset = useCallback(() => {
      onChangeOuter(id, '');
    }, [id, onChangeOuter]);

    const onKeyDownInput = useCallback((ev: React.KeyboardEvent<HTMLInputElement>) => {
      switch (ev.key) {
        default: return;
        case 'Enter': {
          onChangeOuter(id, ev.currentTarget.value);
        } break;
      }
    }, [onChangeOuter]);

    const isInvalid: boolean = useMemo(() => {
      if (value === null || typeof value === 'undefined' || value === '') return false;
      return validate ? !validate(value) : false;
    }, [value, validate]);

    return (
      <TableFilterInputGroup
        name={id}
        label={label ?? id}
        className="flex-grow-1"
        size={size}
        resetDisabled={!Boolean(value)}
        onReset={onReset}
        description={description}
      >
        <Form.Control
          placeholder="Oavsett värde"
          onKeyDown={onKeyDownInput}
          {...extraProps}
          isInvalid={isInvalid}
          isValid={Boolean(value) && !isInvalid}
          name={id}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
        />
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

interface SelectOption {
  value: string;
  label?: React.ReactNode;
}

// a simple select
export const select = (identifier: FilterIdentifier, options: SelectOption[], extendWith = {}): FilterDefinition<string> => ({
  id: identifier[0],
  label: identifier[1],
  description: identifier[2],
  Render: props => {
    const { id, label, description, value, size, validate, onChange:onChangeOuter, extraProps = {} } = props;
    const { placeholder = 'Oavsett värde' } = extraProps;

    const onChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeOuter(id, ev.target.value);
    };

    const isInvalid: boolean = useMemo(() => {
      if (value === null || typeof value === 'undefined' || value === '') return false;
      return !options.some(option => option.value === value);
    }, [value, validate]);

    const onReset = useCallback(() => {
      onChangeOuter(id, '');
    }, [id, onChangeOuter]);

    return (
      <TableFilterInputGroup
        name={id}
        label={label ?? id}
        className="flex-grow-1"
        size={size}
        onReset={onReset}
        resetDisabled={!Boolean(value)}
        description={description}
      >
        <Form.Select
          {...extraProps}
          isInvalid={isInvalid}
          isValid={Boolean(value) && !isInvalid}
          name={id}
          onChange={onChange}
          value={value}
        >
          <option value="">{placeholder}</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label ?? option.value}</option>
          ))}
        </Form.Select>
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

const uuidRegExp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

// string-based, validates a uuid v4
export const uuid = (identifier: FilterIdentifier, extendWith = {}): FilterDefinition<string> => input(identifier, {
  validate: (value: any) => {
    if (typeof value !== 'string') return false;
    return uuidRegExp.test(value);
  },
  ...defaultsDeep({}, extendWith, {extraProps: {placeholder: 'Oavsett ID'}}),
});

// a date selector
export const date = (identifier: FilterIdentifier, extendWith = {}): FilterDefinition<string> => input(identifier, {
  validate: (value: any) => {
    if (typeof value !== 'string') return false;
    return moment(value, 'YYYY-MM-DD').isValid();
  },
  ...defaultsDeep({}, extendWith, {extraProps: {type: 'date'}}),
});

interface NumericIntervalParsedValue {
  lt?: number | '';
  lte?: number | '';
  gt?: number | '';
  gte?: number | '';
}

export const numericInterval = (identifier: FilterIdentifier, extendWith = {}): FilterDefinition<string> => ({
  id: identifier[0],
  label: identifier[1],
  description: identifier[2],
  Render: props => {
    const {
      id,
      label,
      description,
      value:outerValue,
      extraProps,
      size,
      onChange:onChangeOuter,
    } = props;

    const { numberFormat } = extraProps as any;

    const onReset = useCallback(() => {
      onChangeOuter(id, '');
    }, [id, onChangeOuter]);

    const parsedValue: NumericIntervalParsedValue = useMemo(() => {
      const defaultParsedValue = {lte: '', gte: ''};
      if (!outerValue) return defaultParsedValue;
      try {
        return JSON.parse(outerValue);
      } catch (err) {
        return defaultParsedValue;
      }
    }, [outerValue]);

    const ltInclusive = typeof parsedValue.lte !== 'undefined';
    const gtInclusive = typeof parsedValue.gte !== 'undefined';
    const ltValue = (ltInclusive ? parsedValue.lte : parsedValue.lt) ?? '';
    const gtValue = (gtInclusive ? parsedValue.gte : parsedValue.gt) ?? '';

    const onChangeControl = (name: string, value: '' | number) => {
      let key;
      if (name === 'lt') {
        key = ltInclusive ? 'lte' : 'lt';
      } else if (name === 'gt') {
        key = gtInclusive ? 'gte' : 'gt';
      }
      if (!key) return;
      const newParsedValue = {
        ...parsedValue,
        [key]: value,
      };
      onChangeOuter(id, JSON.stringify(newParsedValue));
    };

    const onChangeInclusive: React.MouseEventHandler<HTMLButtonElement> = useCallback(ev => {
      const { name } = ev.currentTarget;
      if (name === 'gtInclusive') {
        const newOuterValue = {
          ...omit(parsedValue, 'gt', 'gte'),
          [gtInclusive ? 'gt' : 'gte']: gtValue,
        };
        onChangeOuter(id, JSON.stringify(newOuterValue));
      } else if (name === 'ltInclusive') {
        const newOuterValue = {
          ...omit(parsedValue, 'lt', 'lte'),
          [ltInclusive ? 'lt' : 'lte']: ltValue,
        };
        onChangeOuter(id, JSON.stringify(newOuterValue));
      }
    }, [onChangeOuter, gtValue, ltValue, gtInclusive, ltInclusive, parsedValue]);

    const targetToValue = (target: any) => {
      const { value, valueAsNumber } = target;
      return value === '' ? '' : valueAsNumber;
    };

    const gtControl = useFocusControl(gtValue, onChangeControl, {targetToValue});
    const ltControl = useFocusControl(ltValue, onChangeControl, {targetToValue});

    return (
      <TableFilterInputGroup
        name={id}
        label={label ?? id}
        className="flex-grow-1"
        size={size}
        onReset={onReset}
        resetDisabled={!Boolean(outerValue)}
        description={description}
      >
        <Button
          variant="outline-secondary"
          onClick={onChangeInclusive}
          name="gtInclusive"
        >
          {gtInclusive ? '≥': '>'}
        </Button>
        <InputFormatted
          className="form-control"
          name="gt"
          placeholder="Oavsett värde"
          isValid={Boolean(gtControl.value)}
          Component={Form.Control}
          numberFormat={numberFormat}
          {...gtControl}
        />
        <InputGroup.Text>och</InputGroup.Text>
        <Button
          variant="outline-secondary"
          onClick={onChangeInclusive}
          name="ltInclusive"
        >
          {ltInclusive ? '≤': '<'}
        </Button>
        <InputFormatted
          name="lt"
          type="number"
          placeholder="Oavsett värde"
          isValid={Boolean(ltControl.value)}
          Component={Form.Control}
          numberFormat={numberFormat}
          {...ltControl}
        />
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

export const companySearch = (identifier: FilterIdentifier, extendWith = {}): FilterDefinition<string> => ({
  id: identifier[0],
  label: identifier[1],
  description: identifier[2],
  Render: props => {
    const { id, label, description, value, size, onChange, extraProps = {} } = props;

    const companySearchManager = useCompanySearchManager({
      onSelect: (orgNumber) => onChange(id, orgNumber),
      isValid: Boolean(value),
    });

    useEffect(() => {
      if (isNull(value)) {
        companySearchManager.clear();
      }
    }, [value]);

    const onReset = useCallback(() => {
      onChange(id, '');
      companySearchManager.clear();
    }, [id, onChange]);

    return (
      <TableFilterInputGroup
        name={id}
        label={label ?? id}
        className="flex-grow-1"
        size={size}
        onReset={onReset}
        resetDisabled={!Boolean(value)}
        description={description}
      >
        <CompanySearch
          {...extraProps}
          className="w-auto flex-grow-1"
          defaultInputValue={value}
          {...companySearchManager.props}
        />
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

interface Option {
  value: string;
  label: string;
}

export const inputQueryDatalist = (identifier: FilterIdentifier, queryOptions: UndefinedInitialDataOptions<Option[]>, extendWith = {}): FilterDefinition<string> => ({
  id: identifier[0],
  label: identifier[1],
  description: identifier[2],
  Render: props => {
    const { id, label, description, size, value:outerValue, onChange:onChangeOuter, extraProps = {} } = props;

    const query = useQuery<Option[]>(queryOptions);

    const listId = useId();
    const list = query.data || [];

    const {
      onFocus,
      onChange,
      onBlur,
      value,
    } = useFocusControl(outerValue, onChangeOuter);

    const isInvalid: boolean = useMemo(() => {
      if (value === null || typeof value === 'undefined' || value === '') return false;
      return !list.some(item => item.value === value);
    }, [value, list]);

    const onReset = useCallback(() => {
      onChangeOuter(id, '');
    }, [id, onChange]);

    return (
      <TableFilterInputGroup
        name={id}
        label={label ?? id}
        className="flex-grow-1"
        size={size}
        onReset={onReset}
        resetDisabled={!Boolean(value)}
        description={description}
      >
        <datalist id={listId}>
          {list.map(item => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </datalist>
        <Form.Control
          placeholder="Oavsett värde"
          list={listId}
          {...extraProps}
          disabled={query.isLoading}
          isInvalid={isInvalid}
          isValid={Boolean(value) && !isInvalid}
          name={id}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
        />
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

export const userSearch = (identifier: FilterIdentifier, extendWith = {}): FilterDefinition<string> => inputQueryDatalist(identifier, {
  queryKey: ['/api/users/list', {limit: 500}],
  select: (data: any) => {
    return data.rows.map((user: IUser) => ({
      value: user.id,
      label: user.name || user.id,
    }));
  },
}, extendWith);

export const simpleModal = (identifier: FilterIdentifier, ModalBody: React.ComponentType<TableFilterSimpleModalBodyProps>, extendWith = {}): FilterDefinition<string> => ({
  id: identifier[0],
  label: identifier[1],
  description: identifier[2],
  Render: props => {
    const { id, label, description, value, size, onChange, extraProps = {} } = props;
    const { placeholder = 'Oavsett värde', buttonLabel = 'Välj', modalTitle = 'Ange värde' } = extraProps;

    const onReset = useCallback(() => {
      onChange(id, '');
    }, [id, onChange]);

    const onChangeValue = useCallback((newValue: string) => {
      onChange(id, newValue);
    }, [id, onChange]);

    const modalProps = {
      ModalBody,
      onChangeValue,
      value,
      title: modalTitle,
    };

    return (
      <TableFilterInputGroup
        name={id}
        label={label ?? id}
        className="flex-grow-1"
        size={size}
        onReset={onReset}
        resetDisabled={!Boolean(value)}
        description={description}
      >
        <Form.Control
          disabled
          value={value}
          placeholder={placeholder}
          isValid={Boolean(value)}
        />
        <ModalOpeningButton
          variant="outline-secondary"
          className="d-flex gap-1 align-items-center px-2 py-0"
          Modal={TableFilterSimpleModal}
          modalProps={modalProps}
          size="sm"
        >
          {buttonLabel}
        </ModalOpeningButton>
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

export const companyEventTypeModal = (identifier: FilterIdentifier, extendWith = {}, searchUrl: string = '/api/company_event/meta'): FilterDefinition<string> => ({
  id: identifier[0],
  label: identifier[1],
  description: identifier[2],
  Render: props => {
    const { id, label, description, value:outerValue, size, onChange:onChangeOuter, extraProps = {} } = props;
    const { placeholder = 'Oavsett värde' } = extraProps;

    const query = useQuery<CompanyMonitoredMeta>({
      queryKey: [searchUrl],
    });

    const value = outerValue ? outerValue.split(',') : [];

    const onChangeValue = (value: string[]) => {
      onChangeOuter(id, value.join(','));
    };

    const onReset = useCallback(() => {
      onChangeOuter(id, '');
    }, [id, onChangeOuter]);

    const items: CompanyEventMetaWatchableItem[] = useMemo(() => {
      if (!query.data?.watchable_groups) return [];
      return query.data.watchable_groups.map(group => {
        return {
          id: group.label,
          recommended: false,
          description: group.subLabel,
          items: group.items,
        };
      });
    }, [query.data?.watchable_groups]);

    const valueAsShown = useMemo(() => {
      if (value.length < 1) return '';
      if (value.length === 1) return value[0];
      return `${value[0]} + ${value.length - 1} andra händelser`;
    }, [value]);

    const modalProps = {
      label: 'Företagshändelser',
      subLabel: 'Filtrera på specifika företagshändelser',
      items,
      value,
      onChangeValue,
      eventTypes: query.data?.event_types,
      showHelp: false,
    };

    return (
      <TableFilterInputGroup
        name={id}
        label={label ?? id}
        description={description}
        className="flex-grow-1"
        size={size}
        onReset={onReset}
        resetDisabled={!Boolean(value)}
      >
        <Form.Control
          disabled
          value={valueAsShown}
          placeholder={placeholder}
          isValid={value.length > 0}
        />
        <ModalOpeningButton
          variant="outline-secondary"
          className="d-flex gap-1 align-items-center px-2 py-0"
          Modal={WatchEventTypesModal as React.FC}
          modalProps={modalProps}
          size="sm"
        >
          Välj händelser
        </ModalOpeningButton>
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});
