import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, CardProps } from 'react-bootstrap';
import { CompanyCommentRow, CompanyCommentRowWithRelations } from 'client/companyComment/types';
import RefreshButton from 'client/buttons/RefreshButton';
import axios from 'client/axios';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as FormikFormControls from 'client/form/FormikFormControls';
import {Formik, Form as FormikForm, FormikHelpers} from 'formik';
import SaveButton from 'client/buttons/SaveButton';
import ErrorAlert from 'client/components/ErrorAlert';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import { UserAnchor } from 'client/user/UserFormatters';
import DateFormat from 'client/components/DateFormat';
import RoleGuard from 'client/guards/RoleGuard';
import classNames from 'classnames';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import OverlaySpinner from 'client/spinners/OverlaySpinner';

interface CompanyCommentQueryResponse {
  rows: CompanyCommentRowWithRelations[];
  total_rows: number;
}

interface CompanyCommentCardProps {
  orgNumber: string;
}

export default function CompanyCommentCard (props: CompanyCommentCardProps) {
  const { orgNumber } = props;

  const query = useQuery<CompanyCommentQueryResponse>({
    queryKey: [`/api/customers/${orgNumber}/comments`, {order: 'created_at'}],
  });

  const onRefetch = () => {
    query.refetch();
  };

  const comments = query.data?.rows || [];

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          Kommentarer
          <div className="d-flex gap-2">
            <InspectObjectModalButton object={comments} />
            <RefreshButton
              onClick={query.refetch}
              disabled={query.isLoading || query.isRefetching}
              size="sm"
            />
          </div>
        </Card.Title>
      </Card.Header>
      <ErrorAlert error={query.error} className="m-3" />
      <Card.Body className="position-relative">
        <OverlaySpinner isLoading={query.isLoading || query.isRefetching} />
        {comments.map((item, index) => (
          <CommentItem
            key={item.id}
            item={item}
            onDelete={onRefetch}
            className={classNames(index === comments.length - 1 ? 'mb-1' : 'mb-3')}
          />
        ))}
        {!comments.length && (
          <p className="mb-0">Det finns inga kommentarer här.</p>
        )}
      </Card.Body>
      <Card.Footer className="p-3">
        <CompanyCommentCardForm orgNumber={orgNumber} onCreate={onRefetch} />
      </Card.Footer>
    </Card>
  );
}

interface CommentItemProps extends CardProps {
  item: CompanyCommentRowWithRelations;
  onDelete?: () => void;
}

function CommentItem (props: CommentItemProps) {
  const { item, onDelete, ...restOfProps } = props;

  const deleteMutation = useMutation({
    mutationFn: vars => axios.delete(`/api/company_comments/${item.id}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Kommentaren har raderats');
      onDelete?.();
    },
  });

  const { comment, creator, created_at } = item;

  return (
    <Card {...restOfProps}>
      <Card.Header className="d-flex justify-content-between bg-white small align-items-center">
        <div>
          <DateFormat value={created_at} format="YYYY-MM-DD HH:mm" />
          {' – '}
          <UserAnchor value={creator} />
        </div>
        <RoleGuard role="admin">
          <ConfirmDeleteButton
            size="sm"
            className="px-1"
            onConfirmedDelete={deleteMutation.mutateAsync}
            confirmMessage="Är du säker på att du vill radera kommentaren?"
            buttonLabel=""
          />
        </RoleGuard>
      </Card.Header>
      <Card.Body className="p-3">
        <pre className="mb-0">{comment}</pre>
      </Card.Body>
    </Card>
  );
}

interface CompanyCommentCardFormData {
  comment: string;
}

interface CompanyCommentCardFormProps {
  orgNumber: string;
  onCreate?: () => void;
}

function CompanyCommentCardForm (props: CompanyCommentCardFormProps) {
  const { onCreate, orgNumber } = props;
  
  const mutation = useMutation<CompanyCommentRow, Error, CompanyCommentCardFormData>({
    mutationFn: vars => axios.post('/api/company_comments', {...vars, org_number: orgNumber}).then(r => r.data),
    onSuccess: () => {
      requestCallbacks.onSuccess('Kommentaren har lagts in');
      onCreate?.();
    },
  });

  const onSubmit = async (form: CompanyCommentCardFormData, helpers: FormikHelpers<CompanyCommentCardFormData>) => {
    await mutation.mutateAsync(form);
    helpers.resetForm();
  };

  const initialValues = {comment: ''};

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <FormikForm>
          <FormikFormControls.Textarea
            className="mb-2"
            name="comment"
            required
            rows={Math.max(1, formProps.values.comment.split('\n').length)}
            placeholder="Ange en kommentar"
          />
          <div className="d-flex justify-content-center">
            <SaveButton
              type="submit"
              isLoading={formProps.isSubmitting}
              disabled={!formProps.isValid || formProps.isSubmitting}
            >
              Spara kommentar
            </SaveButton>
          </div>
          <ErrorAlert error={mutation.error} />
        </FormikForm>
      )}
    </Formik>
  );
}
