import React, {useMemo} from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'client/axios';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import {ColumnDefinition} from 'client/table/types';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import {UserMonitoringCompanyRowWithRelations} from 'client/userMonitoring/types';
import {UserAnchor} from 'client/user/UserFormatters';
import CardSimpleTable from 'client/card/CardSimpleTable';

interface CompanyMonitoredUserTableProps {
  orgNumber: string;
  className?: string;
}

interface TableRow extends UserMonitoringCompanyRowWithRelations {
  id: string;
}

function CompanyMonitoredUserTable (props: CompanyMonitoredUserTableProps) {
  const { className, orgNumber } = props;

  const query = useQuery<UserMonitoringCompanyRowWithRelations[], Error>({
    queryKey: [`/api/company_monitored/${orgNumber}/users`],
  });

  const deleteMutation = useMutation<void, Error, string>({
    mutationFn: userId => axios.delete(`/api/user_monitoring/${userId}/company/${orgNumber}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Användarens bevakning av företaget har tagits bort');
      query.refetch();
    },
  });

  const columns = useMemo(() => columnFactory(deleteMutation.mutateAsync), [deleteMutation]);

  const rows: TableRow[] = useMemo(() => {
    if (!query.data) return [];
    return query.data.map(row => ({...row, id: row.user_id}));
  }, [query.data]);

  return (
    <CardSimpleTable
      className={className}
      query={query}
      title="Användare som bevakar företaget"
      columns={columns}
      rows={rows}
    />
  );
}
export default React.memo(CompanyMonitoredUserTable);

type ColumnFactoryFn = (deleteMutateAsync: (id: string) => Promise<any>) => ColumnDefinition<TableRow>[];

const columnFactory: ColumnFactoryFn = (deleteMutateAsync) => [
  columnDefs.cell(['user_id', 'Användare'], props => (
    <UserAnchor value={props.row.user || props.row.user_id} />
  )),
  columnDefs.date(['created_at', 'Bevakat']),
  columnDefs.actions(props => (
    <ConfirmDeleteButton
      size="sm"
      className="py-0"
      onConfirmedDelete={() => deleteMutateAsync(props.row.user_id)}
      confirmMessage="Är du säker på att du vill ta bort användarens bevakning av detta företag?"
      buttonLabel="Ta bort användarens bevakning"
    />
  )),
];
