import React, { useMemo, useState } from 'react';
import * as numberUtils from 'client/utils/number';

interface InputFormattedProps extends Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'> {
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  Component: React.ComponentType<any>;
  value: '' | number;
  numberFormat?: numberUtils.NumberFormatType;
  [key: string]: React.ComponentProps<any>;
}

// NOTE
// experimental component
export default function InputFormatted (props: InputFormattedProps) {
  const {
    onFocus:onFocusOuter,
    onBlur:onBlurOuter,
    onChange:onChangeOuter,
    value:valueOuter,
    numberFormat = 'number',
    Component,
    ...restOfProps
  } = props;

  const format = useMemo(() => numberUtils.getFormatter(numberFormat).format, [numberFormat]);

  const [focused, setFocused] = useState(false);

  const onFocus: React.FocusEventHandler<HTMLInputElement> = ev => {
    setFocused(true);
    onFocusOuter?.(ev);
  };

  const onBlur: React.FocusEventHandler<HTMLInputElement> = ev => {
    setFocused(false);
    const valueAsNumber = isNaN(ev.target.valueAsNumber) ? '' : ev.target.valueAsNumber;
    ev.target.value = String(valueAsNumber);
    onBlurOuter?.(ev);
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    const value = isNaN(ev.target.valueAsNumber) ? '' : format(ev.target.valueAsNumber);
    onChangeOuter?.(ev, value);
  };

  const value = useMemo(() => {
    if (valueOuter === '') return '';
    if (focused) return valueOuter;
    return format(valueOuter);
  }, [focused, valueOuter, format]);

  return (
    <Component
      {...restOfProps}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      type={focused ? 'number' : 'text'}
      inputMode="numeric"
      value={value}
    />
  );
}
