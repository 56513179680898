import React, {useState} from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import BlockSpinner from 'client/spinners/BlockSpinner';
import RefreshButton from 'client/buttons/RefreshButton';
import { Badge, Card, Form } from 'react-bootstrap';
import { CsGroupStructureRow, CsGroupStructureRowWithRelations } from 'client/cs/types';
import { CompanyAnchor } from 'client/company/CompanyFormatters';
import { CountryFlag } from 'client/components/Flags';
import useAuth from 'client/hooks/useAuth';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import IdProvider from 'client/components/IdProvider';
import OverlaySpinner from 'client/spinners/OverlaySpinner';
import NumberFormat from 'client/components/NumberFormat';
import DateFormat from 'client/components/DateFormat';

interface CompanyGroupStructureResponse {
  top: null | CsGroupStructureRow;
  relations: CsGroupStructureRowWithRelations[];
}

interface CompanyGroupStructureWithChildren extends CsGroupStructureRowWithRelations {
  children: CompanyGroupStructureWithChildren[];
}

interface CompanyGroupStructureCardProps {
  className?: string;
  orgNumber: string;
}

export default function CompanyGroupStructureCard (props: CompanyGroupStructureCardProps) {
  const { orgNumber, className } = props;

  const [showAccountValues, setShowAccountValues] = useState<boolean>(false);
  const onChangeShowAccountValues: React.ChangeEventHandler<HTMLInputElement> = ev => {
    setShowAccountValues(ev.target.checked);
  };

  const auth = useAuth();
  let url = `/api/company/${orgNumber}/groupStructure`;
  if (auth.isCustomer) url = `/api/as_customer/company/${orgNumber}/groupStructure`;

  const query = useQuery<CompanyGroupStructureResponse, Error>({
    queryKey: [url, {include: showAccountValues ? 'cs_accounts' : undefined}],
    placeholderData: keepPreviousData,
  });

  const data = query.data;

  const tree = data && data.relations && data.top ? groupStructureToTree(data.relations, data.top) : null;

  return (
    <Card className={className}>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <Card.Title className="mb-0" as="h6">
          Koncernstruktur
        </Card.Title>
        <div className="d-flex gap-2">
          <InspectObjectModalButton object={tree} size="sm" />
          <RefreshButton onClick={query.refetch} size="sm" />
        </div>
      </Card.Header>
      <Card.Body className="p-3 py-2 border-bottom">
        <IdProvider>
          {id => (
            <Form.Group>
              <Form.Check
                id={id}
                className="mb-0 small"
                inline
                label="Visa värden från senaste bokslut"
                checked={showAccountValues}
                onChange={onChangeShowAccountValues}
                name="showAccountValues"
              />
            </Form.Group>
          )}
        </IdProvider>
      </Card.Body>
      <BlockSpinner isLoading={query.isLoading} className="m-3" />
      <ErrorAlert error={query.error} className="m-3" />
      {query.isSuccess && data && (
        <Card.Body className="position-relative">
          <OverlaySpinner isLoading={query.isRefetching} />
          {tree ? (
            <ul className="mb-1">
              <li>
                <GroupStructureListItem
                  company={tree}
                  currentOrgNumber={orgNumber}
                  showAccountValues={showAccountValues}
                />
              </li>
            </ul>
          ) : (
            <p className="mb-0">Ingen koncernstruktur finns tillgänglig</p>
          )}
        </Card.Body>
      )}
    </Card>
  );
}

interface GroupStructureListItemProps {
  company: CompanyGroupStructureWithChildren;
  showAccountValues: boolean;
  currentOrgNumber?: string;
}

function GroupStructureListItem (props: GroupStructureListItemProps) {
  const { showAccountValues, company, currentOrgNumber } = props;

  const lastAccount = !showAccountValues ? null : company.cs_accounts?.[0];

  return (
    <>
      <div className="d-flex gap-2 align-items-center">
        {company.owned_percent && <small className="text-muted">{company.owned_percent} % av</small>}
        {company.company_country_code === 'SE' && currentOrgNumber !== company.org_number ? (
          <>
            <CompanyAnchor value={company} />
          </>
        ) : (
          <>
            {company.company_name}
            {currentOrgNumber == company.org_number && <Badge>Detta företag</Badge>}
          </>
        )}
        <CountryFlag countryCode={company.company_country_code} />
        <small className="text-muted">{company.org_number}</small>
      </div>
      {showAccountValues && lastAccount && (
        <div className="small text-muted">
          <DateFormat value={lastAccount.date_to} format="YYYY" /> -  
          Omsättning: <strong><NumberFormat value={lastAccount.pl_net_sales} /> tkr</strong> | 
          Vinst: <strong><NumberFormat value={lastAccount.pl_net_profit_loss} /> tkr</strong>
        </div>
      )}
      {company.children.length > 0 && (
        <ul>
          {company.children.map(child => (
            <li key={child.org_number} className="pt-2">
              <GroupStructureListItem
                showAccountValues={showAccountValues}
                key={child.org_number}
                company={child}
                currentOrgNumber={currentOrgNumber}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

function groupStructureToTree (list: CsGroupStructureRow[], parent: CsGroupStructureRow, initialKey: keyof CsGroupStructureRow = 'closest_group_mother_orgnumber'): CompanyGroupStructureWithChildren {
  const children = list.filter(company => {
    return company[initialKey] === parent.org_number;
  }).map(parent => {
    return groupStructureToTree(list, parent);
  });
  return {...parent, children};
}
