import { Switch, Route } from 'react-router-dom';
import PageContainer from 'client/components/PageContainer';
import PrivateRoute from 'client/router/PrivateRoute';
import DashboardPage from 'client/asCustomer/DashboardPage';
import AccountPage from 'client/account/AccountPage';
import CompanyMonitoringPage from 'client/asCustomer/CompanyMonitoringPage';
import CompanyPage from 'client/asCustomer/CompanyPage';
import CompanyTablePage from 'client/asCustomer/CompanyTablePage';
import ErrorAlert from 'client/components/ErrorAlert';
import CompanyEventTablePage from 'client/asCustomer/CompanyEventTablePage';
import publicRoutes from 'client/router/PublicRoutes';

export default function Router () {
  return (
    <Switch>
      <PrivateRoute exact path="/" component={DashboardPage} />
      {publicRoutes}
      <PrivateRoute exact path="/account" component={AccountPage} />
      <PrivateRoute exact path="/monitoring" component={CompanyMonitoringPage} />
      <PrivateRoute exact path="/companies" component={CompanyTablePage} />
      <PrivateRoute exact path="/company/:orgNumber/:tab?" component={CompanyPage} />
      <PrivateRoute exact path="/company_event" component={CompanyEventTablePage} />
      <Route path="*">
        <PageContainer fluid className="p-4">
          <ErrorAlert error="Sidan hittades inte" />
        </PageContainer>
      </Route>
    </Switch>
  );
}
