import React from 'react';
import PageContainer from 'client/components/PageContainer';
import { omit } from 'lodash';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { useParams, useHistory } from 'react-router-dom';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import { Card, Tab, Nav, Row, Col } from 'react-bootstrap';
import TabNavLink from 'client/tab/TabHelpers';
import BlockSpinner from 'client/spinners/BlockSpinner';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import AuthSwitchButton from 'client/buttons/AuthSwitchButton';
import { CustomerRow, CustomerRowWithRelations } from 'client/customer/types';
import CustomerForm, { UpdateCustomerFormFields } from 'client/customer/CustomerForm';
import * as formUtils from 'client/utils/form';
import { Helmet } from 'react-helmet';
import SaveButton from 'client/buttons/SaveButton';
import { maybeAxiosErrorToErrorMap } from 'client/utils/errors';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import { CardHeaderObject } from 'client/card/CardHelpers';
import { CustomerIndustryReportTable, CustomerValuationReportTable } from 'client/customer/CustomerReportTables';
import CustomerEmailsTable from 'client/customer/CustomerEmailsTable';
import CustomerLogTable from 'client/customer/CustomerLogTable';
import RoleGuard from 'client/guards/RoleGuard';
import PageHeader from 'client/components/PageHeader';
import { CompanyAnchor } from 'client/company/CompanyFormatters';
import { StatusLabel } from 'client/customer/CustomerFormatters';
import CustomerMonitoringCompanyTable from 'client/customerMonitoring/CustomerMonitoringCompanyTable';
import {isFiniteNumber} from 'client/utils/number';
import CompanyEventTimelineTableByCustomerCard from 'client/companyEvent/CompanyEventTimelineTableByCustomerCard';
import CompanyCommentCard from 'client/companyComment/CompanyCommentCard';

interface CustomerEditPageReadRelationCountResponse {
  comments: number;
  valuation_reports: number;
  industry_reports: number;
  emails: number;
  company_monitored: number;
}

interface CustomerEditPageParams {
  customerId: string;
  tab?: string;
}

const CustomerEditPage: React.FC = React.memo(function CustomerEditPage () {
  const { customerId, tab = 'form' } = useParams<CustomerEditPageParams>();

  const history = useHistory();

  const readCustomerQuery = useQuery<CustomerRowWithRelations, Error>({
    queryKey: [`/api/customers/${customerId}`],
  });

  const readRelationCountsQuery = useQuery<CustomerEditPageReadRelationCountResponse, Error>({
    queryKey: [`/api/customers/${customerId}/relationCounts`],
  });

  const updateCustomerMutation = useMutation<CustomerRow, Error, Partial<CustomerRow>>({
    mutationFn: vars => axios.patch(`/api/customers/${customerId}`, vars).then(r => r.data),
    onSuccess: () => {
      requestCallbacks.onSuccess('Kunden har uppdaterats');
      readCustomerQuery.refetch();
    },
  });

  const deleteCustomerMutation = useMutation<void, Error>({
    mutationFn: () => axios.delete(`/api/customers/${customerId}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Kunden har raderats');
      history.push('/customers');
    },
  });

  const onSubmit = (form: UpdateCustomerFormFields, helpers: FormikHelpers<UpdateCustomerFormFields>) => {
    const changedCustomer = formValuesToCustomer(form);
    const update = formUtils.changes({...customer, password: ''} as Record<string, any>, changedCustomer);
    updateCustomerMutation.mutateAsync(update)
      .then(updatedCustomer => {
        const newValues = customerToFormValues(updatedCustomer);
        helpers.resetForm({values: newValues});
      }).catch(err => {
        helpers.setSubmitting(false);
        const errorMap = maybeAxiosErrorToErrorMap(err);
        if (errorMap) {
          helpers.setErrors(errorMap);
          return;
        }
        throw err;
      });
  };

  const counts = readRelationCountsQuery.data;
  const customer = readCustomerQuery.data;

  return (
    <PageContainer fluid="sm">
      <Helmet>
        <title>Redigera kund "{customerId}"</title>
      </Helmet>
      <PageHeader breadcrumbs={[{title: 'Kunder', url: '/customers'}, {title: customerId}]}>
        Redigera kund
      </PageHeader>
      <Card>
        <CardHeaderObject
          objectId={customerId}
          extra={customer?.name}
          status={<>
            {customer && (
              <StatusLabel value={customer.inactivated_at} />
            )}
          </>}
        />
        <BlockSpinner isLoading={readCustomerQuery.isLoading} className="m-3" />
        <ErrorAlert error={readCustomerQuery.error} className="m-3" />
        <ErrorAlert error={readRelationCountsQuery.error} className="m-3" />
        {readCustomerQuery.isSuccess && customer && (
          <>
            {customer.cs_basic && (
              <div className="border-bottom py-2 px-3 mb-2 small d-flex align-items-center gap-1">
                <span>Företag:</span>
                <CompanyAnchor value={customer.cs_basic} />
              </div>
            )}
            <Tab.Container
              defaultActiveKey="form"
              activeKey={tab}
              transition={false}
              mountOnEnter
              unmountOnExit
            >
              <Nav className="nav-tabs pt-3 px-3">
                <TabNavLink
                  eventKey="form"
                  title="Formulär"
                />
                <TabNavLink
                  eventKey="comments"
                  title="Kommentarer"
                  count={counts?.comments}
                />
                <TabNavLink
                  eventKey="valuation_reports"
                  title="Värderingar"
                  count={counts?.valuation_reports}
                />
                <TabNavLink
                  eventKey="industry_reports"
                  title="Branschrapporter"
                  count={counts?.industry_reports}
                />
                <TabNavLink
                  eventKey="emails"
                  title="E-post"
                  count={counts?.emails}
                />
                <RoleGuard role="admin">
                  <TabNavLink
                    eventKey="monitored"
                    title="Bevakade företag"
                    count={counts?.company_monitored}
                  />
                  <TabNavLink
                    eventKey="company_event_timeline_batch"
                    title="Bevakningshändelser"
                  />
                </RoleGuard>
                <TabNavLink
                  eventKey="log"
                  title="Logg"
                />
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="form" className="p-4">
                  <Formik initialValues={customerToFormValues(customer)} onSubmit={onSubmit}>
                    {formikBag => (
                      <FormikForm>
                        <Card>
                          <Card.Body className="pb-1">
                            <CustomerForm formikBag={formikBag} />
                            <ErrorAlert error={updateCustomerMutation.error} />
                          </Card.Body>
                          <Card.Footer className="d-flex justify-content-end gap-2 p-2">
                            <SaveButton
                              type="submit"
                              isLoading={updateCustomerMutation.isPending}
                              disabled={!formikBag.isValid || formikBag.isSubmitting || !Object.keys(formikBag.touched).length}
                            />
                          </Card.Footer>
                        </Card>
                      </FormikForm>
                    )}
                  </Formik>
                </Tab.Pane>
                <Tab.Pane eventKey="comments" className="p-4">
                  <Row>
                    <Col md={12} lg={6}>
                      <CompanyCommentCard orgNumber={customerId} />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="valuation_reports" className="p-4">
                  <CustomerValuationReportTable customerId={customerId} />
                </Tab.Pane>
                <Tab.Pane eventKey="industry_reports" className="p-4">
                  <CustomerIndustryReportTable customerId={customerId} />
                </Tab.Pane>
                <Tab.Pane eventKey="emails" className="p-4">
                  <CustomerEmailsTable customerId={customerId} />
                </Tab.Pane>
                <RoleGuard role="admin">
                  <Tab.Pane eventKey="monitored" className="p-4">
                    <CustomerMonitoringCompanyTable customerId={customerId} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="company_event_timeline_batch" className="p-4">
                    <CompanyEventTimelineTableByCustomerCard customerId={customerId} />
                  </Tab.Pane>
                </RoleGuard>
                <Tab.Pane eventKey="log" className="p-4">
                  <CustomerLogTable customerId={customerId} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <Card.Footer className="p-3">
              <ErrorAlert error={deleteCustomerMutation.error} />
              <div className="d-flex gap-2">
                <InspectObjectModalButton object={customer} size="lg" className="px-2" />
                <RoleGuard role="admin">
                  <ConfirmDeleteButton
                    onConfirmedDelete={deleteCustomerMutation.mutateAsync}
                    confirmMessage="Är du säker på att du vill radera kunden permanent?"
                    buttonLabel="Radera kunden"
                  />
                </RoleGuard>
                {customer.inactivated_at === null ? (
                  <AuthSwitchButton
                    objectType="customer"
                    objectId={customer.id}
                    variant="outline-primary"
                  />
                ) : ''}
              </div>
            </Card.Footer>
          </>
        )}
      </Card>
    </PageContainer>
  );
});
export default CustomerEditPage;

function customerToFormValues (customer?: CustomerRow): UpdateCustomerFormFields {
  const {
    name = '',
    email = null,
    contact_person = null,
    inactivated_at = null,
    language = '',
    company_monitored_limit = null,
  } = customer || {};
  return {
    name,
    email: email || '',
    change_password: false,
    contact_person: contact_person || '',
    company_monitored_limit: company_monitored_limit || '',
    password: '',
    active: !Boolean(inactivated_at),
    language,
  };
}

function formValuesToCustomer (form: UpdateCustomerFormFields): Partial<CustomerRow> {
  const customer = omit(form, 'change_password', 'password', 'active') as Partial<CustomerRow>;
  if (form.change_password) customer.password = form.password;
  if (typeof form.active === 'string') customer.inactivated_at = form.active === 'true' ? null : new Date();
  if (!isFiniteNumber(customer.company_monitored_limit)) customer.company_monitored_limit = null;
  return customer;
}
