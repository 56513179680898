import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { CustomerMonitoringCompanyRowWithRelations, CustomerMonitoringFolderRow } from 'client/customerMonitoring/types';
import RefreshButton from 'client/buttons/RefreshButton';
import { Alert, Badge, Card } from 'react-bootstrap';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import TableVirtual from 'client/table/TableVirtual';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import axios from 'client/axios';
import {ColumnDefinition} from 'client/table/types';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import Pluralizer from 'client/components/Pluralizer';
import {AlertCircle} from 'react-feather';
import useTableSelectRows, { SelectedRows, UseTableSelectRows } from 'client/hooks/useTableSelectRows';
import { TableProvider } from 'client/contexts/TableContext';
import TableBatchForm, {TableBatchFormExtendedActionData} from 'client/table/TableBatchForm';
import useTableState, {OrderDirection} from 'client/hooks/useTableState';
import {MonitorCompanyForm} from 'client/companyMonitored/CompanyMonitoredUtils';
import MonitoringFolderNav from 'client/monitoring/MonitoringFolderNav';

const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.DESC,
};

interface CompanyMonitoringCardProps {
  className?: string;
}

interface CustomerMonitoringCompanyQueryResponse {
  folders: CustomerMonitoringFolderRow[];
  rows: CustomerMonitoringCompanyRowWithRelations[];
  limit: number;
  email: string;
  count: number;
}

interface TableRow extends CustomerMonitoringCompanyRowWithRelations {
  id: string;
}

export default function CompanyMonitoringTableCard (props: CompanyMonitoringCardProps) {
  const { className } = props;

  const [activeFolderId, setActiveFolderId] = useState<string>('null');
  const onSelectActiveFolderId = (newTab: string) => {
    setActiveFolderId(newTab);
    setSelectedRows({});
  };

  const deleteMutation = useMutation<void, Error, string>({
    mutationFn: orgNumber => axios.delete(`/api/as_customer/monitoring/${orgNumber}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Bevakningen har tagits bort');
      query.refetch();
    },
  });

  const columns = useMemo(() => columnFactory(deleteMutation.mutateAsync), [deleteMutation.mutateAsync]);

  const tableState = useTableState({
    defaultState,
    defaultColumnsVisible: columns.map(column => column.id),
  });

  const query = useQuery<CustomerMonitoringCompanyQueryResponse, Error>({
    queryKey: ['/api/as_customer/monitoring', {order: tableState.params.order, folder_id: activeFolderId ?? 'null'}],
    placeholderData: keepPreviousData,
  });

  const data = query.data;
  const folders = query.data?.folders ?? [];

  const rows: TableRow[] = useMemo(() => {
    if (!query.data?.rows) return [];
    return query.data.rows.map(row => ({...row, id: row.org_number}));
  }, [query.data?.rows]);

  const rowIds = useMemo(() => {
    return rows.map(r => r.id);
  }, [rows]);

  const [selectedRows, setSelectedRows] = useState<SelectedRows>({});
  const tableSelectRows = useTableSelectRows(rowIds, selectedRows, setSelectedRows);

  const { limitReached, limitOverflow } = useMemo(() => {
    const limitReached = data ? data.limit - data.count <= 0 : false;
    const limitOverflow = data ? data.limit - data.count : 0;
    return {limitReached, limitOverflow};
  }, [data]);

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 p-3">
        <Card.Title as="h6" className="mb-0 p-0 d-flex flex-wrap gap-2 justify-content-between align-items-center">
          <span>
            Bevakade företag{' '}
            {data && rows && (
              <Badge bg="secondary">{Math.max(0, data.limit - rows.length)}/{data.limit} företag kvar</Badge>
            )}
          </span>
          <RefreshButton
            onClick={query.refetch}
            disabled={query.isLoading || query.isRefetching}
            size="sm"
          />
        </Card.Title>
      </Card.Header>
      <CardBodyErrorAlert error={query.error} />
      {query.isSuccess && data && limitReached && (
        <Card.Body className="border border-bottom-0">
          <Alert className="my-1 d-flex align-items-center gap-2">
            <span>
              <AlertCircle size={36} />
            </span>
            Maxgränsen för hur många företag som går att bevaka är {limitOverflow === 0 ? 'nådd' : `överskriden med ${limitOverflow} st företag`}.
            Ta bort något av de befintliga bevakade företagen från listan eller kontakta oss på Calculate om du vill justera gränsen.
          </Alert>
        </Card.Body>
      )}

      {folders && (
        <MonitoringFolderNav
          folders={folders}
          onSelectActiveFolderId={onSelectActiveFolderId}
          activeFolderId={activeFolderId}
          folderUrlBase="/api/as_customer/monitoring/folder"
          onReload={query.refetch}
        />
      )}

      <MonitorCompanyForm
        onReload={query.refetch}
        existingOrgNumbers={rowIds}
        limitReached={limitReached}
        createUrlBase="/api/as_customer/monitoring"
        batchUrl="/api/as_customer/monitoring/batch"
        batchSearchUrl="/api/as_customer/company/searchBatch"
        companySearchUrl="/api/as_customer/company/search"
        activeFolderId={activeFolderId === 'null' ? null : activeFolderId}
      />

      <CompanyMonitoringTableBatchForm
        tableSelectRows={tableSelectRows}
        onReload={query.refetch}
        folders={folders}
        activeFolderId={activeFolderId}
      />
      <TableSpinningOverlay isLoading={query.isRefetching}>
        <TableProvider tableSelectRows={tableSelectRows} tableSort={tableState.tableSort}>
          <div className="table-responsive">
            <TableVirtual
              isInitialLoading={query.isLoading}
              className="mb-0 border align-middle"
              columns={columns}
              rows={rows}
            />
          </div>
        </TableProvider>
      </TableSpinningOverlay>
      <Card.Footer className="border border-top-0 py-3">
        Totalt {rows.length}{' '}
        <Pluralizer
          count={rows.length}
          zero="bevakade företag i denna mapp"
          one="bevakat företag i denna mapp"
          more="bevakade företag i denna mapp"
        />
      </Card.Footer>
    </Card>
  );
}

const columnSortable = [
  'org_number',
  'created_at',
];

type ColumnFactory = (deleteMutateAsync: (orgNumber: string) => Promise<any>) => ColumnDefinition[];

const columnFactory: ColumnFactory = deleteMutateAsync => [
  columnDefs.select(),
  columnDefs.cell(['name', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.cs_basic} />
  )),
  columnDefs.cell(['status', 'Företagsstatus'], props => (
    <CompanyFormatters.StatusLabel value={props.row.cs_basic.status_text_high} />
  )),
  columnDefs.date(['created_at', 'Bevakat']),
  columnDefs.actions(props => (
    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
      <ConfirmDeleteButton
        size="sm"
        className="px-1 py-0"
        onConfirmedDelete={() => deleteMutateAsync(props.row.org_number)}
        confirmMessage="Är du säker på att du vill ta bort bevakningen av detta företag?"
        buttonTitle="Ta bort bevakningen"
        buttonLabel="Ta bort bevakning"
      />
    </div>
  )),
].map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);

interface CompanyMonitoringTableBatchFormProps {
  tableSelectRows: UseTableSelectRows;
  onReload?: () => Promise<any>;
  folders?: CustomerMonitoringFolderRow[];
  activeFolderId?: string;
}

const CompanyMonitoringTableBatchForm = React.memo(function CompanyMonitoringTableBatchForm (props: CompanyMonitoringTableBatchFormProps) {
  const { tableSelectRows, onReload, folders = [], activeFolderId } = props;

  const actions = useMemo(() => {
    return [
      {value: 'unmonitor', data: {action: 'unmonitor'}, label: 'Ta bort bevakningen'},
      ...folders.filter(folder => folder.id !== activeFolderId && folder.id).map(folder => ({
        value: `move_to_${folder.id}`,
        data: {action: 'move', folder_id: folder.id === 'null' ? undefined : folder.id},
        label: `Flytta till "${folder.name}"`,
      })),
    ];
  }, [folders]);

  const batchMutation = useMutation<unknown[], Error, TableBatchFormExtendedActionData>({
    mutationKey: ['CompanyMonitoringTableBatchForm'],
    mutationFn: async vars => {
      const result = await axios.post<any, unknown[]>('/api/as_customer/monitoring/batch', {
        ...vars,
        org_numbers: tableSelectRows.selectedRowIds,
      });
      if (onReload) await onReload();
      tableSelectRows.setSelectedRows({});
      requestCallbacks.onSuccess('Åtgärden har utförts');
      return result;
    },
  });

  return (
    <TableBatchForm
      className="border border-bottom-0 d-flex flex-wrap align-items-center gap-2 p-2 small"
      tableSelectRows={tableSelectRows}
      batchMutation={batchMutation}
      actions={actions}
    />
  );
});
