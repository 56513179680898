import React, { useMemo, useCallback, useState } from 'react';
import { TChangeValue } from 'client/utils/form';

export type HTMLFormControls = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

interface UseFocusControlOptions <T = any> {
  targetToValue?: (target: HTMLFormControls, controlledValue: T) => T
}

// useFocusControl can be used for a form field that can be controlled by the user
// when it is focused, and set to a fixed value when it is not focused
export default function useFocusControl <T = any, K = string> (uncontrolledValue: T, onChangeValue: TChangeValue<T, K>, options: UseFocusControlOptions<T> = {}) {
  const {
    targetToValue = target => target.value as T,
  } = options;

  const [focused, setFocused] = useState<boolean>(false);
  const [controlledValue, setControlledValue] = useState<T>(uncontrolledValue);

  const onFocus = useCallback((ev: React.FocusEvent<HTMLElement>) => {
    if (!focused) {
      setFocused(true);
      setControlledValue(uncontrolledValue);
    }
  }, [focused, setFocused, setControlledValue, uncontrolledValue]);

  const onBlur = useCallback((ev: React.FocusEvent<HTMLFormControls>) => {
    // when blurring we also update the controlled value to the target value
    // this is because some form components won't call onChange
    const value = targetToValue(ev.target, controlledValue);
    setControlledValue(value);

    // onChangeValue can optionally return a promise, which means we will only give control
    // of the value back (focused=false) when the promise resolves
    const name = ev.target.name as K;
    const maybePromise = onChangeValue(name, value);
    if (typeof maybePromise?.finally === 'function') {
      maybePromise.finally(() => {
        setFocused(false);
      });
    } else {
      setFocused(false);
    }
  }, [setFocused, onChangeValue, controlledValue, targetToValue]);

  const onChange = useCallback((ev: React.ChangeEvent<HTMLFormControls>) => {
    const value = targetToValue(ev.target, controlledValue);
    setControlledValue(value);
  }, [targetToValue, setControlledValue, controlledValue]);

  const value = useMemo(() => {
    return focused ? controlledValue : uncontrolledValue;
  }, [focused, controlledValue, uncontrolledValue]);

  return {
    onFocus,
    onChange,
    onBlur,
    value,
  };
}
