import React, {useMemo} from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import axios from 'client/axios';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import {ColumnDefinition} from 'client/table/types';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import {CustomerMonitoringCompanyRowWithRelations} from 'client/customerMonitoring/types';
import {CustomerAnchor} from 'client/customer/CustomerFormatters';
import CardSimpleTable from 'client/card/CardSimpleTable';

interface CompanyMonitoredCustomerTableProps {
  orgNumber: string;
  className?: string;
}

interface TableRow extends CustomerMonitoringCompanyRowWithRelations {
  id: string;
}

function CompanyMonitoredCustomerTable (props: CompanyMonitoredCustomerTableProps) {
  const { className, orgNumber } = props;

  const query = useQuery<CustomerMonitoringCompanyRowWithRelations[], Error>({
    queryKey: [`/api/company_monitored/${orgNumber}/customers`],
  });

  const deleteMutation = useMutation<void, Error, string>({
    mutationFn: customerId => axios.delete(`/api/customer_monitoring/${customerId}/${orgNumber}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Kundens bevakning av företaget har tagits bort');
      query.refetch();
    },
  });

  const columns = useMemo(() => columnFactory(deleteMutation.mutateAsync), [deleteMutation]);

  const rows: TableRow[] = useMemo(() => {
    if (!query.data) return [];
    return query.data.map(row => ({...row, id: row.customer_id}));
  }, [query.data]);

  return (
    <CardSimpleTable
      className={className}
      query={query}
      title="Kunder som bevakar företaget"
      columns={columns}
      rows={rows}
    />
  );
}
export default React.memo(CompanyMonitoredCustomerTable);

type ColumnFactoryFn = (deleteMutateAsync: (id: string) => Promise<any>) => ColumnDefinition<TableRow>[];

const columnFactory: ColumnFactoryFn = (deleteMutateAsync) => [
  columnDefs.cell(['customer_id', 'Kund'], props => (
    <div>
      {props.row.customer_id && (
        <CustomerAnchor value={props.row.customer || props.row.customer_id} />
      )}
    </div>
  )),
  columnDefs.date(['created_at', 'Bevakat']),
  columnDefs.actions(props => (
    <ConfirmDeleteButton
      size="sm"
      className="py-0"
      onConfirmedDelete={() => deleteMutateAsync(props.row.customer_id)}
      confirmMessage="Är du säker på att du vill ta bort kundens bevakning av detta företag?"
      buttonLabel="Ta bort kundens bevakning"
    />
  )),
];
